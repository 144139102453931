import { ReactNode } from "react";
import Head from "~/src/components/layouts/Head";
import { ServerInformation } from "~/src/configs/server";
import { ProjectHistory } from "~/src/constants";

function MainPage(): ReactNode {
  return (
    <>
      <Head>
        <title>불타는 이 공간</title>
      </Head>
      <div>불타는 이 공간</div>
      <dl>
        <dt>여기는 왜 불타는 공간인가?</dt>
        <dd>그냥 저 아이콘이 마음에 들었다.</dd>
        <dd>
          그 외에도 이런 이유가 있다. 여기는 dothome 무료 홈페이지! 제약이 있다.
          <ol>
            <li>SSL/TLS 없다!</li>
            <li>SSH 접속 불가! FTP로 써야 한다!</li>
            <li>PHP 5.6 와우! MySQL 5.5 와아아아우!</li>
            <li>현재의 instance 자체가 곧 Server!</li>
            <li>EUC-KR 와우!!!!!</li>
          </ol>
        </dd>
      </dl>
      <hr />
      <div>
        난 이런 조치를 취했다...
        <dl>
          <dt>형상 관리</dt>
          <dd>비밀번호 노출되지 않는 형상관리를 GitLab으로!</dd>
        </dl>
        <dl>
          <dt>배포 과정</dt>
          <dd>GitLab CI를 통해서 FTP 배포!</dd>
        </dl>
        <dl>
          <dt>개발</dt>
          <dd>React + Parcel 통해서 개발 생산성 높이기!</dd>
        </dl>
        <dl>
          <dt>의존성 관리</dt>
          <dd>Zero Install 위한 Yarn Berry 활용...</dd>
        </dl>
        이게 다인 듯...?
      </div>
      Connected : {JSON.stringify(ServerInformation)}
      <hr />
      {JSON.stringify(ProjectHistory)}
    </>
  );
}

export default MainPage;
